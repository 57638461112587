import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import {useThemeVar} from "../../Utility/siteConfig";
import ValidationError from "../components/ValidationError";

export default function RequestedSaveCart({
  config,
  form,
  flavour = 'requested',
  saveCartStatus = {},
  shoppingCart,
  ...props
}) {
  const {register, watch, formState} = form;
  const { errors } = formState;

  const listName = useThemeVar("mailingListName", null);

  return <div>
    <h2 className="text-2xl text-brand-primary my-4">
      {config.headline}</h2>

    <p className="my-4">{config.prompt}</p>


    <input type="text"
      placeholder={config.firstNamePlaceholder || "First Name"}
      className="text-gray-600 px-4 py-4"
      autoComplete="given-name"
      {...register('firstName',
        {required: {value: true, message: config.firstNameError}})} />

    <input type="email"
      placeholder={config.emailPlaceholder || "Email Address"}
      className="text-gray-600 px-4 py-4"
      autoComplete="email"
      {...register('email',
        {required: {value: true, message: config.emailError}})} />

    {listName && <label className="font-bold text-gray-500 text-center">
      <input type="checkbox"
        name="mailingListSignup"
        value="1"
        {...register('mailingListSignup')}/>
      {' '}
      <span>
        Sign up for {' '}
        <span className="underline">{listName}</span>?
        {' '}
        <i>(optional)</i>
      </span>
    </label>
    }

    <ValidationError form={form} />

    <input className={clsx(config.buttonClasses || "text-white bg-brand-orange")}
      type="submit" value={config.buttonLabel || "Save Cart"}/>
  </div>

}

/*
 <input type="text"
 id=""
 name="first_name"
 value={contents.first_name || ''}
 onChange={this.onChange}
 autoComplete="given-name"
 placeholder="First Name"/>

 <input type="checkbox"
 id="saved_cart_mailing_list_signup"
 name={name}
 checked={!!contents[name]}
 onChange={handleChange}
 value="1"/>

 const listName = mailingListNames[theme];
 import {
 mailingListNames
 } from "../../Utility/staticConfig";

 if (!theme || !listName) return null;
 */
