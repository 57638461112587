import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useThemeVar} from "../../Utility/siteConfig";

export default function SaveCartLogo({...props}) {

  const logo = useThemeVar("saveCartLogo");

  if (!logo)
    return null;

  return <div className="text-center pb-4 px-8">
    <img
      className="inline-block mx-auto"
      style={{height: "100px"}}
      src={logo} alt={useThemeVar("displayName", "logo")}/>
  </div>

}
