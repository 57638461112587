import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {isEmpty, sortBy, keys} from 'lodash';

export default function FlatRateShippingLookAtMe({
  shoppingCart, checkoutConfig,
  ...props
}) {
  if (!shoppingCart || !checkoutConfig) return null;

  const {flatRateShipping} = checkoutConfig;

  if (isEmpty(flatRateShipping))
    return null;

  const {effectiveSubtotal, effectiveShipping} = shoppingCart;

  if (effectiveShipping === 0) {
    return <YouGotFreeShipping/>
  }

  const requiredSubtotal = getQualifyingSubtotal(flatRateShipping)
  if (!requiredSubtotal || !effectiveSubtotal)
    return null;

  let centsMore = requiredSubtotal - effectiveSubtotal;
  if (centsMore < 0) return null;

  return <AddMoreToGetFreeShipping centsMore={centsMore}/>
}

function AddMoreToGetFreeShipping({centsMore}) {
  let moneyMore = new Money(centsMore);

  return (
    <div className="text-lg mb-4 grid grid-cols-2 gap-2 bg-brand-med-grey text-brand-light px-2"
      style={{gridTemplateColumns: "3rem auto"}}>
      <div className="pt-4 text-center">
        <span className="text-2xl fas fa-shopping-cart text-brand-primary" />
      </div>
      <div className="leading-5 py-2">
        {'ADD '}
        <span className="text-brand-primary">{moneyMore.toString()}</span>
        {' MORE TO YOUR CART TO GET '}
        <span className="whitespace-nowrap text-brand-primary">FREE SHIPPING</span>
      </div>
    </div>
  );
}

function getQualifyingSubtotal(shippingMatrix) {
  if (isEmpty(shippingMatrix))
    return null;

  let levels = sortBy(keys(shippingMatrix));
  for (let k of levels) {
    let shipCost = shippingMatrix[k];
    if (shipCost === 0)
      return k;
  }
}

function YouGotFreeShipping() {
  return (
    <div className="text-lg mb-4 grid grid-cols-2 gap-2 bg-brand-med-grey text-brand-light px-2 pt-2 pb-1"
      style={{gridTemplateColumns: "3rem auto"}}>
      <div className="py-2 text-center leading-5">
        <span className="text-2xl fas fa-check-circle text-brand-primary"/>
      </div>
      <div className="py-2 text-base">
        FREE SHIPPING APPLIED
      </div>
    </div>
  );
}
