import React, {useState, useEffect} from 'react';
import {omitBy} from 'lodash';
import {useForm} from "react-hook-form";
import {getDispatch} from "../../Utility/ReduxHelper";
import {submitSaveCart} from "../../ReduxStore/ShoppingCart/saveCartSlice";
import AutomaticSaveCartForm from "../automatic/AutomaticSaveCartForm";
import RequestedSaveCart from "../requested/RequestedSaveCart";

export default function SaveCartForm({
  flavour,
  config,
  saveCartStatus,
  shoppingCart,
}) {
  const form = useForm();

  flavour ||= config.flavour || 'fallback';

  const requested = (flavour === 'requested');
  let UI = requested ? RequestedSaveCart : AutomaticSaveCartForm;

  const onSubmit = formData => {
    const dispatch = getDispatch();
    const {cartCode} = (shoppingCart || {});

    formData = Object.assign({flavour, cartCode}, formData);

    // delete mailingListSignup: false
    formData = omitBy(formData, (v,k) =>  (v===false) || (v===null));

    dispatch(submitSaveCart({cartCode, formData}))
  }

  const {handleSubmit  } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UI
        form={form} config={config} flavour={flavour}
        saveCartStatus={saveCartStatus}
        shoppingCart={shoppingCart}/>
    </form>
  );
}
