// Pricing Column
import React from "react";
import AgentPriceAssist from '../agents/AgentPriceAssist';
import Money from '../../../lib/Money';

export default function CartItemPrices({item, shoppingCart, callbacks, currentAgent}) {
  const hasSavings = item.extendedSavings && (item.extendedSavings > 0);

  return <div className="col-span-2 text-right">
    {hasSavings ?
      <ItemPricingDetailWithSavings item={item}/> :
      <ItemPricingDetail item={item}/>}

    {currentAgent && <AgentPriceAssist item={item}
      shoppingCart={shoppingCart} callbacks={callbacks} />}
  </div>
}

// Prices section at top of pricing column.
// version is used only when there is no discount; see also ItemPricingDetailWithSavings
function ItemPricingDetail({item}) {
  return <div className="whitespace-nowrap text-right">
      {new Money(item.effectiveExtendedPrice).toString()}
  </div>
}

// Prices section at top of pricing column.
// version is used only when there is a discount; see also ItemPricingDetail
function ItemPricingDetailWithSavings({item}) {

  return <div className="whitespace-nowrap text-right">
    <div className="line-through">
      {new Money(item.regularExtendedPrice).toString()}
    </div>

    {item.agentExtendedSavings > 0 ?
      <div className="agent-assisted">Your private discount:</div> : null}

    <div className="text-orange text-brand-med-grey">
      -{new Money(item.extendedSavings).toString()}
    </div>

    <div className="">
      {new Money(item.effectiveExtendedPrice).toString()}
    </div>
  </div>
}

