import React from "react";
import {getDispatch} from "../../Utility/ReduxHelper";
import saveCartSlice from "../../ReduxStore/ShoppingCart/saveCartSlice";
import clsx from "clsx";

// this is the text link at the bottom, not the modal-close "X" at the top
export default function CloseSaveCart({
  className, handleClose, caption}) {

  handleClose ||= function () {
    const dispatch = getDispatch();
    dispatch(saveCartSlice.actions.mergeStatus({
      open: false, status: 'closed'}));
  }

  return <div className="text-center my-4">
    <a className={clsx(className)} onClick={handleClose}>
      {caption || 'Close'}</a>
  </div>
}
