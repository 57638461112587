import React from 'react';

import { ErrorBoundary } from "react-error-boundary";

/**
 * This is just a simple wrapper for react-error-boundary that provides
 * the required fallback param.
 *
 * @param children
 * @param message
 * @param fallback
 * @param props
 * @returns {Element}
 * @constructor
 */
export default function CatchErrors({
  children, label='(unlabeled CatchErrors)',
  fallbackRender=DefaultFallback, ...props}) {

  const renderError = errorProps => {
    const { error } = errorProps;
    console.log(`rendering error for ${label}: ${error}`, errorProps );
    window.RENDER_ERROR = error;

    // React insists on a capital letter here.
    const F = fallbackRender;
    return <F label={label} {...errorProps} />
  }

  return <ErrorBoundary fallbackRender={renderError}>{children}</ErrorBoundary>
}


function DefaultFallback({ error, resetErrorBoundary, label, ...props }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert my-4">
      <div className="text-2xl mb-2">Something went wrong:</div>
      <div className="w-full text-red-500 whitespace-pre-wrap">
        {error.message}
      </div>
      <div className="debug">Error label: {label} </div>
      <div className="my-2 text-right">
      {resetErrorBoundary && <a onClick={resetErrorBoundary}>Reset</a>}
      </div>
    </div>
  );
}
