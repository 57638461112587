import React from 'react';
import _, {camelCase} from 'lodash';
import AffirmFormSection from "./AffirmFormSection";
import PaypalFormSection from "./PaypalFormSection";
import AppleFormSection from "./AppleFormSection";
import AgentNoPaymentFormSection from "./AgentNoPaymentFormSection";
import BraintreeFormSection from "./BraintreeFormSection";

const SECTION_TYPES = {
  affirm: AffirmFormSection,
  creditCard: BraintreeFormSection,
  paypal: PaypalFormSection,
  apple: AppleFormSection,
  agentNone: AgentNoPaymentFormSection,
  default: FallbackSection
}

export default function DynamicPaymentPanel(props) {
  const { checkout={}  } = props;
  if (!checkout)
    return null;

  const paymentType = camelCase(props.paymentType)

  const DynamicSection = SECTION_TYPES[paymentType] || FallbackSection;

  return <DynamicSection {...props} />
}

function FallbackSection({paymentType, ...props}) {
  if (paymentType) {
    return <div className="text-red-800">
      ERROR, unknown payment type {paymentType}
    </div>
  }

  return null;
}
