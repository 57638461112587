import React from 'react';

// modernized 2025-04
export default function AgentNoteTool({callbacks, shoppingCart}) {
  const handleChange = (evt) => {
    callbacks.updateCart({agentComment: evt?.target?.value})
  }

  return (
    <textarea
      className="text-white bg-agent-purple w-full placeholder-gray-300 p-2"
      name="agent_comment"
      defaultValue={shoppingCart.agentComment}
      placeholder="Agent: write a note to help you remember this customer."
      onBlur={handleChange}
    />
  )

}
