import React from 'react';
import Modal from "../../Modals/Modal";
import {hideSaveCart, submitSaveCart} from "../../ReduxStore/ShoppingCart/saveCartSlice";
import {getDispatch} from "../../Utility/ReduxHelper";
import {getClassList} from "../../Utility/siteConfig";
import SaveCartForm from "../components/SaveCartForm";
import CloseSaveCart from "../components/CloseSaveCart";
import {getSaveCartConfig} from "../services/saveCartLogic";

export default function SaveCartModal(props) {
  const {saveCartStatus = {}, shoppingCart = {}} = props;

  const {flavour = 'fallback'} = saveCartStatus;

  const requested = (flavour === 'requested');
  const config = getSaveCartConfig(flavour);

  function handleClose() {
    const dispatch = getDispatch();
    dispatch(hideSaveCart())
  }

  let className = getClassList({
    key: (requested ? 'SaveCartModal' : 'AutomaticSaveCartModal'),
    base: "top-2 p-2",
    fallback: (requested ? "text-pencil bg-paper" : // FIXME
      "rounded-lg bg-brand-blue text-white ")
  });

  return <Modal handleClose={handleClose} className={className}>
    <div className="m-2 p-2">
      <SaveCartForm
        flavour={flavour}
        config={config}
        saveCartStatus={saveCartStatus}
        shoppingCart={shoppingCart}
        {...props} />
      <CloseSaveCart
        className={config.closeClasses}
        handleClose={handleClose}
        caption={config.closeLabel}
      />
    </div>
  </Modal>

}
