import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ShoppingCartTop from '../components/above/ShoppingCartTop';
import CartSidebar from '../components/sidebar/CartSidebar';
import ViewCartReporter from "../components/analytics/ViewCartReporter";
import NewItemsReporter from "../components/analytics/NewItemsReporter";
import BraintreeTokenFetcher from "../../Checkout/braintree/BraintreeTokenFetcher";
import CatchErrors from "../../Utility/CatchErrors";
import {useSkeletonLoading} from "../../hooks/useSkeletonLoading";
import CheckoutSteps from "../../Checkout/components/general/CheckoutSteps";
import CartCouponTool from "../components/below/CartCouponTool";
import AgentBelowCartToolbar from "../components/agents/AgentBelowCartToolbar";
import CartRefresh from "../components/below/CartRefresh";
import CartItemsHead from "../components/cart_contents/CartItemsHead";
import CartItemsBody from "../components/cart_contents/CartItemsBody";

const isDev = (process.env.NODE_ENV == 'development');

// 2023-06 made Braintree-aware; verified shoppingCart and all descendants are camelcased
export default function ShoppingCartUI(props) {
  const { checkoutConfig } = props;

  const ref = useSkeletonLoading();
  const {shoppingCart, currentAgent, dispatch, braintreeData, emailUser} = props;

  if (!shoppingCart || !shoppingCart.cartCode) {
    return null;
  }
  return <div ref={ref}>
    <CatchErrors label="checkoutSteps">
      <CheckoutSteps current={1} callbacks={{setStep: () => {}}}/>
      <BraintreeTokenFetcher cartCode={shoppingCart.cartCode} dispatch={dispatch}/>
    </CatchErrors>

    <CatchErrors label="cartTop"><ShoppingCartTop {...props} /></CatchErrors>

    <CatchErrors label="reporting">
      <ViewCartReporter {...props} />
      <NewItemsReporter {...props} />
    </CatchErrors>

    <CatchErrors label="viewCart">
      <div className="grid lg:grid-cols-4 gap-2">
        <div className="lg:col-span-3">
          <ShoppingCartDisplay {...props} />
        </div>
        <CatchErrors label="sidebar"><CartSidebar {...props} /></CatchErrors>
      </div>
    </CatchErrors>

  </div>
}

function ShoppingCartDisplay({...props}) {
  return <div>
    <CatchErrors label="cartTable">
      <div id="cart-items-table">
        <CartItemsHead/>
        <CartItemsBody {...props} />
      </div>
    </CatchErrors>

    <CatchErrors label="belowCartTable">
      <CartCouponTool {...props} />
      <CartRefresh {...props} />
    </CatchErrors>

    <div className="clear-me">
      {props.currentAgent && <AgentBelowCartToolbar {...props} />}
    </div>
  </div>
}

//

// PUT /cart/xxx.json _method=patch
// cart_code,
// shopping_cart { coupon_code: x}
// content-type: application/json;charset=UTF-8
//
// {saveCart.open &&
// <SaveCartContainer {...props} />}
