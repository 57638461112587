import clsx from "clsx";
import React from "react";

export default function AttributeChoiceButton({
  choice, selected = false, onSelect, cluster, outOfStock = false}) {
  let value = choice.v;

  let className = clsx(
    "inline-block m-2 p-2 border cursor-pointer relative group text-center",
    ((outOfStock && !selected) ?
      "text-gray-500 border-gray-500 border-dashed" : "text-brand-dark border-brand-dark"),
    (selected ? 'bg-brand-dark text-white' : 'bg-white')
  );

  let label = choice.l || choice.v;

  function handleClick(evt) {
    // clicking when already selected will undo the selection
    onSelect(selected ? null : value);
  }

  return <div className="relative inline-block group">
    {outOfStock && <OOSTooltip />}
    <a className={className} onClick={handleClick}>
      {label}</a></div>
}

function OOSTooltip(props) {
  const className = clsx(
    "hidden group-hover:block  speech-bubble-bottom",
    "absolute -top-8 left-1/2 -translate-x-1/2",
    "bg-brand-dark text-brand-light z-200",
    "px-4 py-1 whitespace-nowrap"
  );

  return <div
    style={{"--speech-bubble-colour": "#231F20"}}
    className={className}>
    out of stock
  </div>
}

