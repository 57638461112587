import React from 'react';
import { isEmpty } from 'lodash';

export default function ValidationError({form}) {
  const { formState={} } = form;
  const { errors } = formState;

  if (isEmpty(errors))
    return null;

  return (
    <div className="bg-red-900 text-white py-2 my-2">
      {Object.keys(errors).map(k => {
        let e = errors[k];
        let msg = e?.message || `Please enter your ${k}`;
        return <div className="my-1">{msg}</div>
      })}
    </div>
  );
}

