import React from 'react';
import _ from 'lodash';
import CloseSaveCart from "./CloseSaveCart";
import Modal from "../../Modals/Modal";
import {getDispatch} from "../../Utility/ReduxHelper";
import saveCartSlice from "../../ReduxStore/ShoppingCart/saveCartSlice";

// modernized 2025-03
export default function SaveCartSuccess({saveCartStatus={}, email}) {
  email ||= _.get(saveCartStatus, 'formData.email');

  function handleClose(e) {
    const dispatch = getDispatch();
    dispatch(saveCartSlice.actions.mergeStatus({
      status: 'saved'}));
  }

  return <Modal handleClose={handleClose}>
    <div>
      <h3>Success!</h3>

      {email ? <p>A link to your cart has been emailed to <b>{email}</b>.</p> :
        <p>A link to your cart has been emailed to you.</p>}

      <CloseSaveCart handleClose={handleClose}/>
    </div>
  </Modal>

}


