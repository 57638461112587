import SaveCartSuccess from "../components/SaveCartSuccess";
import SaveCartModal from "./SaveCartModal";
import SaveCartFailed from "../components/SaveCartFailed";

export default function SaveCartUi({
  saveCartStatus = {},
  shoppingCart
}) {
  const {status, open = false} = saveCartStatus;

  if (status === "success") {
    return <SaveCartSuccess saveCartStatus={saveCartStatus}/>
  }

  if (status === "failed" || status === "error") {
    return <SaveCartFailed saveCartStatus={saveCartStatus}/>
  }

  if (!open)
    return null;

  return <SaveCartModal
    saveCartStatus={saveCartStatus}
    shoppingCart={shoppingCart}
  />
}
