import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PaymentTypeButton from "./PaymentTypeButton";

export default function PaymentTypePicker(props) {
  const {allTypes} = props;

  return <div className="my-2">
    <ul>
      {allTypes.map(type => <PaymentTypeButton  {...props}
        key={type} paymentType={type}/>)}
    </ul>
  </div>

}

PaymentTypePicker.propTypes = {
  selectedType: PropTypes.string,
  allTypes: PropTypes.array.isRequired,
  callbacks: PropTypes.object.isRequired
}

PaymentTypePicker.defaultProps = {
  selectedType: 'credit_card',
  allTypes: ['credit_card', 'paypal']
}
