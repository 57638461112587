import React from 'react';

export default function CartItemsHead(props) {

  return <div className="p-4 bg-gray-200 hidden lg:block">
    <div className="grid grid-cols-12">
      <div className="col-span-7">Item Description</div>
      <div className="col-span-1">Qty</div>
      <div className="col-span-2">Shipping</div>
      <div className="col-span-2 text-right">Price</div>
    </div>
  </div>
}
