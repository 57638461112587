import React from 'react';
import clsx from "clsx";
import SaveCartLogo from "./SaveCartLogo";
import SaveCartHeadline from "./SaveCartHeadline";
import {getSaveCartConfig} from "../services/saveCartLogic";
import ValidationError from "../components/ValidationError";

export default function AutomaticSaveCartForm({
  config,
  form,
  flavour,
  saveCartStatus = {},
  shoppingCart,
  ...props
}) {
  flavour ||= saveCartStatus.flavour || 'fallback';
  config ||= getSaveCartConfig(flavour);

  const {register, watch, formState} = form;

  return <div>
    <SaveCartLogo saveCartStatus={saveCartStatus} />

    <SaveCartHeadline
      config={config}
      saveCartStatus={saveCartStatus}
      shoppingCart={shoppingCart} />

    <p className="my-4">{config.prompt}</p>

    <input type="email"
      placeholder={config.emailPlaceholder || "Email Address"}
      className="text-gray-600"
      autoComplete="email"
      {...register('email',
        {required: {value: true, message: config.emailError}})}
    />

    <ValidationError form={form} />

    <input className={clsx(config.buttonClasses || "text-white bg-brand-orange")}
      type="submit" value={config.buttonLabel|| "Save Cart"}/>
  </div>
}

// style={{xxwidth: '800px'}}>
