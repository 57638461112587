import React, {useState} from 'react';
import PropTypes from 'prop-types';

import ExitIntentWatcher from "./ExitIntentWatcher";
import SaveCartTrigger from "./SaveCartTrigger";
import {getSaveCartFlavour} from "./saveCartLogic";
import {showSaveCart} from "../../ReduxStore/ShoppingCart/saveCartSlice";
import {getDispatch} from "../../Utility/ReduxHelper";
import FakeSaveCartInvoker from "./FakeSaveCartInvoker";
import CatchErrors from "../../Utility/CatchErrors";

const isDev = (process.env.NODE_ENV == 'development');

export default function SaveCartInvoker({
  saveCartStatus = {},
  delaySeconds = 4, // (isDev ? 20 : 4),
  shoppingCart,
  ...props
}) {
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [triggered, setTriggered] = useState(false);

  const trigger = new SaveCartTrigger({
    delaySeconds, startTime, triggered
  });

  function handleSaveCart(flavour) {
    const dispatch = getDispatch();
    flavour ||= getSaveCartFlavour(shoppingCart);

    console.log(`SaveCartInvoker: show ${flavour || '(null)'}`);

    dispatch(showSaveCart({flavour}));
  }

  // CALLBACK - invoked when user intends to leave page.
  const handleExitPage = (evt) => {
    // This prevents it from displaying if the user has pressed the SaveCart button
    if (saveCartStatus.openedAt || saveCartStatus.closedAt) {
      // console.log("already opened: " + saveCartStatus.openedAt + " / closed " + saveCartStatus.closedAt);
      return false;
    }

    if (!trigger.canTrigger())
      return false;

    setTriggered(n => n + 1);
    trigger.setTriggered(true);

    handleSaveCart();
  }

  return <CatchErrors>
    <ExitIntentWatcher onExitPage={handleExitPage}/>
    {isDev && <FakeSaveCartInvoker handleSaveCart={handleSaveCart} />}
  </CatchErrors>
}


SaveCartInvoker.propTypes = {
  shoppingCart: PropTypes.object.isRequired,
  saveCartStatus: PropTypes.object.isRequired,
};

