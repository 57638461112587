import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Money from "../../../lib/Money";
import clsx from "clsx";

const AgentPercentDiscountField = ({
  editItem,
  saveItem,
  item = {},
  shoppingCart = {},
  value,
  violated=false
}) => {

  const onInput = (evt) => {
    const agentPercentDiscount = +(evt.target.value);  // 5, 10, 20

    // 2022-11 use cataloguePrice instead of regularUnitPrice
    const cataloguePrice = item.cataloguePrice || item.regularUnitPrice;

    const centsDiscount = Math.round(cataloguePrice * agentPercentDiscount / 100.0);

    const agentUnitPrice = cataloguePrice - centsDiscount;
    // let multiplier = (100.0 - percent) / 100.0;
    // let agentUnitPrice = Math.round(multiplier * regularUnitPrice);

    editItem({
      agentUnitPrice,
      centsDiscount,
      agentPercentDiscount: evt.target.value,
      agentUnitPriceFormatted: new Money(agentUnitPrice).toDollars()
    })
  }

  const onBlur = (evt) => {
    saveItem({ agentPercentDiscount: value })
  }

  let className = clsx(
    "w-20 p-1 my-1 border-2 text-right",
    (violated ? "bg-red-100 text-red-800 border-red-800" :
      "bg-white text-agent-purple border-agent-purple")
  )

  return (
    <input
      className={className}
      placeholder="% off"
      value={value || ''}
      onInput={onInput}
      onBlur={onBlur}
      readOnly={shoppingCart.readonly || false}/>
  );
}

export default AgentPercentDiscountField;

