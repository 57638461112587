import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Money from "../../../lib/Money";
import clsx from "clsx";

const isDev  = (process.env.NODE_ENV == 'development');

const AgentUnitPriceField = ({
  editItem,
  saveItem,
  item = {},
  shoppingCart = {},
  value,
  violated=false
}) => {

  const onInput = (evt) => {
    let newVal = evt.target.value;  // string, may have '.'

    if (_.isEmpty(newVal) || (newVal === "0")) {
      editItem({
        centsDiscount: 0,
        agentPercentDiscount: 0,
        agentUnitPrice: null,
        agentUnitPriceFormatted: ''
      });
      return;
    }

    let dollars = Money.fromDollars(+newVal);
    const agentUnitPrice = dollars.value(); // cents

    const cataloguePrice = item.cataloguePrice || item.regularUnitPrice;
    const centsDiscount = cataloguePrice - dollars.value();
    const agentPercentDiscount = 100.0 * (cataloguePrice - agentUnitPrice) / cataloguePrice;

    editItem({
      centsDiscount,
      agentPercentDiscount: agentPercentDiscount.toFixed(1),
      agentUnitPrice,  // cents
      agentUnitPriceFormatted: newVal,  // RAW value as typed, for next render
    });
  }

  const onBlur = (evt) => {
    if (!value) {
      console.log("AgentUnitPriceField - value blank - ignore");
      return;
    }

    saveItem({agentUnitPrice: Math.round(100.0 * (+value))})
  }

  let className = clsx(
    "w-20 p-1 my-1 border-2 text-right",
    (violated ? "bg-red-100 text-red-800 border-red-800" :
      "bg-white text-agent-purple border-agent-purple")
  )

  return (
    <input
      className={className}
      placeholder="$ each"
      name={`cart_items[${item.id}][agent_unit_price]`}
      value={value || ""}
      onInput={onInput}
      onBlur={onBlur}
      readOnly={shoppingCart.readonly || false}/>
  );
}

export default AgentUnitPriceField;

