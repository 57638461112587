// SHipping column, with forecast
import React from "react";
import ShippingForecastDisplay from "./ShippingForecastDisplay";

export default function CartItemShipping(props) {
  const {item, forecast, checkoutConfig = {}} = props;
  if (!item)
    return null;

  return <div className="col-span-2 col-shipping">
    <div className="shipping">
      <ShippingCostDisplay checkoutConfig={checkoutConfig} item={item}/>

      <ShippingForecastDisplay forecast={forecast}/>
    </div>
  </div>
}

function ShippingCostDisplay({checkoutConfig = {}, item={}}) {
  const {flatRateShipping} = checkoutConfig;

  let {effectiveShippingTotal = 0} = item;

  if (!effectiveShippingTotal) {
    return <div className="text-brand-secondary font-bold">Free Shipping</div>
  }

  if (flatRateShipping) return null;

  // // tags: list of strings like [ "prop65", "klaviyo-welcome1", ]
  // let delayed = _.includes(productGroup.tags, "covid-delay");
  //
  let shipDollars = new Money(effectiveShippingTotal);

  return <div className="item-shipping">{shipDollars.toString()}</div>
}


