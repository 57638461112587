import OnceADaySentinel from "../../lib/OnceADaySentinel";

const isDev = (process.env.NODE_ENV == 'development');

const DEFAULT_DELAY = isDev ? 20 : 60;

export default class SaveCartTrigger {
  constructor({delaySeconds = DEFAULT_DELAY, startTime = 0, triggered = 0}) {
    this.delaySeconds = delaySeconds;
    this.startTime = startTime;
    this.triggered = triggered;
  }

  getSentinel() {
    return new OnceADaySentinel({key: "saveCartOffered"});
  }

  setTriggered() {
    this.triggered = 1 + this.triggered;
    this.getSentinel().write();
  }

  canTrigger() {
    if (this.triggered) {
      console.log("SaveCartInvoker - already triggered");
      return false;
    }

    if (!this.checkElapsedTime())
      return false;

    if (!this.checkSentinel())
      return false;

    return true;
  }

  checkSentinel() {
    if (!this.getSentinel().sentinelPresent())
      return true;

    if (isDev) {
      console.log("SaveCartInvoker - sentinel is present, ignoring it in in dev mode");
      return true;
    }

    console.log("SaveCartInvoker - sentinel is present, will not trigger.");
    return false;
  }

  // return true if time is OK.
  checkElapsedTime() {
    const startTime = this.startTime;

    if (!startTime)
      return true;

    let now = new Date().getTime();
    let delay = 1000 * +(this.delaySeconds);

    let diff = now - startTime;
    if (diff > delay)
      return true;

    console.log(`elapsed ${diff}ms, not triggering until ${delay}ms`);
    return false;
  }
}

