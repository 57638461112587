import React, {useEffect} from 'react';
import cartSlice, {
  deleteCartItem,
  fetchCart,
  fetchForecasts,
  updateCartItem,
  updateCart
} from "../../ReduxStore/ShoppingCart/cartSlice";

import PulsingLinesThrobber from "../../Common/components/PulsingLinesThrobber";
import ShoppingCartUI from "./ShoppingCartUI";
import {showSaveCart} from "../../ReduxStore/ShoppingCart/saveCartSlice";
import CatchErrors from "../../Utility/CatchErrors";

export default function ShoppingCartContainer(props) {
  const {shoppingCart, dispatch} = props;
  const cartCode = props.cartCode || shoppingCart?.cartCode;

  const hasCart = !!(shoppingCart && cartCode);

  const loadCart = () => {
    const {authToken} = props;

    // discard the old cart, just so we can see a throbber
    dispatch(cartSlice.actions.setCart({shoppingCart: null}));

    // now load the real one
    dispatch(fetchCart({cartCode, authToken}));
  }

  // STARTUP EFFECT - this page initially loads with a dummy
  // cart object; load the real one.
  useEffect(() => {
    if (!hasCart)
      loadCart();

    dispatch(fetchForecasts({cartCode}));
  }, []);

  const deleteItem = (payload) => {
    payload = Object.assign({cartCode}, payload);

    dispatch(deleteCartItem(payload));
  }

  const updateItem = (payload) => {
    payload = Object.assign({
      cartCode: shoppingCart.cartCode
    }, payload);

    dispatch(updateCartItem(payload));
  }

  const updateCartCallback = (changes) => {
    const {shoppingCart, dispatch} = props;

    // console.log("update cart: changes = ", changes);

    let payload = {
      cartCode: shoppingCart.cartCode,
      changes: changes
    };

    dispatch(updateCart(payload));
  }

  const openSaveCart = (opts = {}) => {
    props.dispatch(showSaveCart(opts));
  }

  const callbacks = {
    loadCart,
    // FIXME: lots of references to this callback in lower components
    updateCart: updateCartCallback,
    updateItem,
    deleteItem,
    openSaveCart,
    dispatch  // make dispatch available to child components as we migrate away from callbacks
  }

  if (shoppingCart) {
    return <CatchErrors label="cartContainer">
      <ShoppingCartUI callbacks={callbacks} {...props} />
    </CatchErrors>
  } else {
    return <PulsingLinesThrobber/>
  }
}


