// Price fields for the agents.
import React from "react";
import _ from "lodash";
import Money from "../../../lib/Money";
import AgentPercentDiscountField from "./AgentPercentDiscountField";
import AgentUnitPriceField from "./AgentUnitPriceField";
import clsx from "clsx";

function buildState(props) {
  let {item = {}} = props;

  let state = _.pick(item, "agentUnitPrice", "agentPercentDiscount");

  state.agentUnitPriceFormatted = new Money(state.agentUnitPrice).toDollars();

  return state;
}

export default class AgentPriceAssist extends React.Component {
  state = buildState(this.props);

  componentDidUpdate(prevProps, prevState) {
    console.log("AgentPriceAssist did update", this.props.item);

    let a = _.pick(prevProps.item, "agentUnitPrice", "agentPercentDiscount")
    let b = _.pick(this.props.item, "agentUnitPrice", "agentPercentDiscount");
    if (_.isEqual(a, b)) return;

    this.setState(buildState(this.props))
  }

  // discount exceeds the private_ceiling?


  render() {
    let props = this.props;

    const {item, shoppingCart, callbacks} = props;
    const {privateCeilingPercent = 0} = item;

    let violated = hasBadDiscount({item, ...this.state});

    // we send the formatted version to the widget, as this will
    // be the user's keystrokes when editing (or formatted value if from elsewhere)
    let {agentPercentDiscount, agentUnitPrice = 0, agentUnitPriceFormatted} = this.state;

    if (agentUnitPrice && !agentUnitPriceFormatted)
      agentUnitPriceFormatted = new Money(agentUnitPrice).toDollars();

    const editItem = changes => {
      console.log("AgentPriceAssist.edit: ", changes);
      this.setState(changes);
    }

    const saveItem = changes => {
      console.log("AgentPriceAssist.save: ", changes);

      callbacks.updateItem({
        id: item.id,
        changes
      });
    }

    return <>
      <AgentPercentDiscountField
        item={item}
        editItem={editItem} saveItem={saveItem}
        violated={violated}
        maxPercent={privateCeilingPercent}
        callbacks={this.callbacks}
        shoppingCart={shoppingCart}
        value={agentPercentDiscount || ''}/>

      <AgentUnitPriceField
        item={item}
        editItem={editItem} saveItem={saveItem}
        violated={violated}
        maxPercent={privateCeilingPercent}
        callbacks={this.callbacks}
        shoppingCart={shoppingCart}
        value={agentUnitPriceFormatted || ''}/>
    </>
  }

}

function hasBadDiscount({item, agentPercentDiscount=0}) {
  const {privateCeilingPercent = 0} = item;

  return (+agentPercentDiscount) > (+privateCeilingPercent);
}
