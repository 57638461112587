import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ValidAddressDisplay from "./ValidAddressDisplay";
import {camelizeKeysDeep} from "../../../ReduxStore/Helpers/transformObject";

/**
 * ReviewAddress - give a brief summary of address with oppportunity to change it (go back)
 *
 * @param headline
 * @param address
 * @param editUrl
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function ReviewAddress({
  headline = "Ship To: ",
  paymentType,  // relevant only for paypal
  handleChangeAddress,
  address,  // 2025-03 snake case, but camel below here
  usage,
  impediments = [],
  ...props
}) {
  if (_.isEmpty(address))
    return null;

  address = camelizeKeysDeep(address);

  handleChangeAddress ||= () => { console.warn("no handleChangeAddress") };

  let valid = (address.city && address.lastName);

  return (<div className="my-4 pb-4 border-b border-gray-200">
    <h3 className="uppercase">{headline}</h3>

    {valid ?
      <ValidAddressDisplay
        usage={usage}
        address={address}
        handleChangeAddress={handleChangeAddress}
        impediments={impediments}
        paymentType={paymentType}
      /> :
      <InvalidAddressDisplay usage={usage}
        address={address}
        handleChangeAddress={handleChangeAddress}/>}
  </div>);
}

ReviewAddress.propTypes = {
  usage: PropTypes.string.isRequired,
  impediments: PropTypes.array,
  // if 'false', suppress the change link
  // changeAddress: PropTypes.oneOf([PropTypes.func, PropTypes.bool])
}

function InvalidAddressDisplay({handleChangeAddress}) {
  return <div className="address address-bad">
    <strong>WARNING: </strong>
    Your address is not set. Please
    {' '}
    <a className="pointer-cursor underline"
      onClick={handleChangeAddress}>go back</a>
    {' '}
    and enter your shipping address.
  </div>
}
