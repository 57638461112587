import LocalUrlMaker from "../../../lib/LocalUrlMaker";
import React from "react";

export default function CartItemPicture({item}) {
  let img = item.smallImage || item.midImage;
  let url = LocalUrlMaker.prefixedPath(item.groupUrl);

  return <div className="col-span-2">
    {img && <a className="bg-red-200" href={url}>
      <img className="w-full" src={img} alt={item.name}/></a>}
  </div>
}
