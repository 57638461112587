import React from 'react';
import _ from 'lodash';
import CloseSaveCart from "./CloseSaveCart";
import Modal from "../../Modals/Modal";
import {getDispatch} from "../../Utility/ReduxHelper";
import saveCartSlice from "../../ReduxStore/ShoppingCart/saveCartSlice";

// modernized 2025-03
export default function SaveCartFailed({saveCartStatus = {}, email}) {
  function handleClose(e) {
    const dispatch = getDispatch();
    dispatch(saveCartSlice.actions.mergeStatus({
      status: 'reset'
    }));
  }

  const message = saveCartStatus.error || saveCartStatus.message ||
    "Unspecified error";

  return <Modal handleClose={handleClose}>
    <div className="p-4">
      <h3 className="text-red-800 text-2xl my-4">Failed</h3>

      <p className="my-4">Sorry, we were unable to save your cart. The error was:</p>

      <p className="my-4">{message}</p>

      <CloseSaveCart handleClose={handleClose}/>
    </div>
  </Modal>

}


